import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import image from "./aboutus.png";
import AboutUsCard from "./AboutUsCard";
import { Helmet } from "react-helmet-async";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [exclamations, setExclamations] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setExclamations((prev) => {
        if (prev.length >= 4) {
          return "";
        }
        return prev + "!";
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Helmet>
        <title>About Us - Who We Are and What We Do at ByteCrafts</title>
        <meta
          name="description"
          content="At ByteCrafts, we are a passionate team dedicated to shaping digital experiences. Discover how our innovative solutions empower businesses and enhance user engagement."
        />
      </Helmet>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{
            marginTop: "10vh",
            paddingLeft: isMobile ? "6vh" : "18vh",
          }}
        >
          <Grid item xs={12} style={{ paddingTop: "20px" }}>
            <Typography style={{ color: "#FB8686", fontSize: "24px" }}>
              Who we are
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: { xs: "24px", sm: "50px", md: "50px", lg: "50px" },
                whiteSpace: "nowrap",
                fontWeight: "bold",
              }}
            >
              A Creative team shaping Digital
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: { xs: "24px", sm: "50px", md: "50px", lg: "50px" },
                whiteSpace: "nowrap",
                fontWeight: "bold",
              }}
            >
              experiences with passion{exclamations}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "4vh" }}>
          <img
            src={image}
            alt="About Us"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "100vw",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: "2vh",
            paddingRight: { xs: "23px", sm: "10vh", md: "10vh", lg: "10vh" },
            // paddingLeft: { xs: "23px", sm: "10vh", md: "10vh", lg: "10vh" },
            marginLeft: { xs: "23px", sm: "10vh", md: "10vh", lg: "10vh" },
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontSize: { xs: "23px", sm: "30px", md: "40px", lg: "40px" },
              textAlign: "justify",
              padding: "20px",
              // whiteSpace: "nowrap",
            }}
          >
            Welcome to <span style={{ color: "#fb8686" }}>Bytecrafts</span>, a
            dynamic and innovative startup dedicated to providing customized
            solutions tailored to meet the unique needs of your business. Our
            expertise spans across various domains, including{" "}
            <span style={{ color: "#fb8686" }}>
              website development, mobile application development, and SEO
              optimization
            </span>{" "}
            , ensuring your brand achieves a strong digital presence. We take
            pride in delivering high-quality, user-centric products that enhance
            functionality and customer experience.
          </Typography>
          <Typography
            sx={{
              color: "#000000",
              fontSize: { xs: "23px", sm: "30px", md: "40px", lg: "40px" },
              textAlign: "justify",
              marginTop: "4vh",
              padding: "20px",
              // whiteSpace: "nowrap",
            }}
          >
            At Bytecrafts, we believe in pushing boundaries and continuously
            evolving. In addition to our current offerings, we are excited to be
            expanding into the field of{" "}
            <span style={{ color: "#fb8686" }}> data science</span>, leveraging
            data-driven insights to help businesses make smarter decisions.
          </Typography>
          <Typography
            sx={{
              color: "#000000",
              fontSize: { xs: "23px", sm: "30px", md: "40px", lg: "40px" },
              textAlign: "justify",
              marginTop: "4vh",
              // whiteSpace: "nowrap",
              padding: "20px",
            }}
          >
            Whether you're a startup or an established business,{" "}
            <span
              style={{
                fontSize: { xs: "25px", sm: "35px", md: "45px", lg: "45px" },
              }}
            >
              Bytecrafts is your partner in digital transformation
            </span>
            , committed to turning your ideas into reality with precision,
            creativity, and technological excellence.
          </Typography>
        </Grid>

        {/* <Grid
          item
          xs={12}
          sx={{
            marinTop: "10vh",
            marginBottom: "4vh",
            marginLeft: { xs: "3vh", sm: "8vh", md: "8vh", lg: "8vh" },
          }}
        >
          <Typography
            sx={{
              color: "#fb8686",
              fontSize: { xs: "23px", sm: "35px", md: "35px", lg: "35px" },
              textAlign: "justify",
              marginTop: { xs: "23px", sm: "35px", md: "35px", lg: "35px" },
              // whiteSpace: "nowrap",
            }}
          >
            Our Work+
          </Typography>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          style={{
            marginTop: "4vh",
            paddingLeft: isMobile ? "6vh" : "18vh",
          }}
        >
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: { xs: "23px", sm: "50px", md: "50px", lg: "50px" },
                whiteSpace: "nowrap",
              }}
            >
              Building Innovative Solutions that
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: { xs: "24px", sm: "50px", md: "50px", lg: "50px" },
                whiteSpace: "nowrap",
              }}
            >
              elevate your <span style={{ color: "#FB8686" }}>Online</span>{" "}
              Presence.
            </Typography>
          </Grid>
        </Grid> */}
      </Grid>
      <Grid>
        <Box
          sx={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.6)",
            borderRadius: "8px",
            overflow: "hidden",
            marginBottom: 10,
            margin: 10,
          }}
        >
          <AboutUsCard />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ backgroundColor: "#F7990C", padding: "60px" }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: {
                xs: "20px",
                sm: "60px",
                md: "70px",
                lg: "70px",
                xl: "70px",
              },
            }}
            fontWeight={700}
            color={"whitesmoke"}
          >
            Building Innovative Solutions
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight={700}
            color="whitesmoke"
            sx={{
              fontSize: {
                xs: "20px",
                sm: "60px",
                md: "70px",
                lg: "70px",
                xl: "70px",
              },
            }}
          >
            that elevates your Online Presence!!!
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default AboutUs;
