import React, { useState } from "react";
import { Box, Typography, Card, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ProductsCarousel = () => {
  const [animationPlayState, setAnimationPlayState] = useState("running");

  const products = [
    {
      title: "RefEase",
      img: "/assets/products/refease.jpeg",
      description:
        "We revolutionize dental imaging with seamless collaborative digital solution",
      onClick: "/products/refease",
      testid: "Refease-img",
    },
    {
      title: "StockNest",
      img: "/assets/products/stocknests.jpeg",
      description:
        "We streamline your clinic’s inventory management with tailored stock management system",
      onClick: "/products/stocknest",
      testid: "Stocknest-img",
    },
    {
      title: "NamNews",
      img: "/assets/products/namnews.jpeg",
      description:
        "We help local newspapers to thrive with a robust online presence",
      onClick: "/products/namnews",
      testid: "Namnews-img",
    },
    // Add more products as needed
  ];
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setAnimationPlayState("paused");
  };

  const handleMouseLeave = () => {
    setAnimationPlayState("running");
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        background: "#FBC3C32E",
        height: "500px",
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Typography
        variant="h4"
        align="start"
        sx={{
          margin: "20px",
          color: "#FB8686",
          position: "absolute",
          top: "10px",
          width: "100%",
          fontWeight: "bold",
        }}
      >
        Our Products
      </Typography>
      <Box
        sx={{
          display: "flex",
          whiteSpace: "nowrap",
          animation: `scrollLeft 20s linear infinite ${animationPlayState}`,
        }}
      >
        {products.map((product, index) => (
          <Card
            key={index}
            data-testid={product.testid}
            sx={{
              minWidth: "250px",
              marginRight: "50px",
              display: "inline-block",
              cursor: "pointer",
              overflow: "hidden",
              position: "relative",
              transition: "transform 1s",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
            onClick={() => navigate(product.onClick)}
          >
            <CardMedia
              component="img"
              width="1000"
              image={product.img}
              alt={product.title}
              style={{ objectFit: "fill", width: "300px", height: "300px" }}
              sx={{
                filter: "brightness(0.7)", 
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                opacity: 0,
                color: "#FFF",
                transition: "opacity 0.3s",
                textAlign: "center", 
                padding: "10px",
                boxSizing: "border-box",
                "&:hover": {
                  opacity: 1,
                },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "10px", 
                  wordWrap: "break-word", 
                  fontWeight: "bold",
                }}
              >
                {product.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.9rem", 
                  lineHeight: "1.2", 
                  wordWrap: "break-word", 
                  whiteSpace: "normal", 
                  overflow: "hidden", 
                  textOverflow: "ellipsis",
                  maxWidth: "90%",
                }}
              >
                {product.description}
              </Typography>
            </Box>
          </Card>
        ))}
      </Box>

      <style>
        {`
          @keyframes scrollLeft {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default ProductsCarousel;
