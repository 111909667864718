import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const WorkWithUs = () => {
  return (
    <Grid
      item
      xs={12}
      style={{
        backgroundColor: "#F7990C",
        padding: "20px",
        paddingBottom: 60,
        paddingTop: 60,
        marginTop: 30,
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            marginLeft: 8,
            fontSize: {
              xs: "40px",
              sm: "60px",
              md: "70px",
              lg: "70px",
              xl: "70px",
            },
          }}
          fontWeight={700}
          color={"whitesmoke"}
        >
          Work With Us
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          fontWeight={700}
          color="whitesmoke"
          sx={{
            marginLeft: 8,
            fontSize: {
              xs: "40px",
              sm: "60px",
              md: "70px",
              lg: "70px",
              xl: "70px",
            },
          }}
        >
          To build a better tomorrow!!!
        </Typography>
      </Grid>
    </Grid>
  );
};
export default WorkWithUs;
