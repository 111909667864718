import React, { useEffect } from "react";
import Section1 from "./New/Section1";
import NoticeBar from "./noticeBar";
import Cards from "./New/Cards";
import CardsMobile from "./New/CardsMobile";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet-async";
import ProductsCarousel from "../Components/ProductsCarousel";

const brandNames = [
  {
    name: "BAP Logo",
    url: "/logos/baplogo.webp",
    link: "https://www.bapujidental.edu/",
  },
  {
    name: "ImageO",
    url: "/logos/imageo.webp",
    link: "https://www.elitespower.com/",
  },
  {
    name: "White Logo 11",
    url: "/logos/white_logo11.webp",
    link: "https://dashrdp.com/",
  },
  {
    name: "Classic",
    url: "/logos/Classic-removebg-preview.webp",
    link: "https://www.classicdental.in/",
  },
  {
    name: "Bio123",
    url: "/logos/bio123new.webp",
    link: "https://www.biophilinpharma.com/",
  },
  // {
  //   name: "PharmIT Logo",
  //   url: "/logos/pharmit_logo.webp",
  //   link: "https://hr.pharmit.in/",
  // },
];
const brands = [
  {
    heading: " Website Building",
    desc1: (
      <>
        We enhance your{" "}
        <span style={{ color: "#FB8686" }}>Digital presence</span>
      </>
    ),
    desc2: "through great websites, User Experience and",
    desc3: "design",
    image: "/services/endtoend.webp",
  },
  {
    heading: "Mobile Application Development",
    desc1: (
      <>
        We create <span style={{ color: "#FB8686" }}>innovative</span> &
      </>
    ),
    desc2: (
      <>
        <span style={{ color: "#FB8686" }}>user friendly</span> mobile apps
      </>
    ),
    desc3: "than bring ideas to life on any platform",
    image: "/services/mobileapp.webp",
  },
  {
    heading: "End-to-end Product Development",
    desc1: (
      <>
        We deliver complete <span style={{ color: "#FB8686" }}>product</span>
      </>
    ),
    desc2: (
      <>
        <span style={{ color: "#FB8686" }}>solutions</span> from concept to
        launch
      </>
    ),
    desc3: (
      <>
        tailored to meet
        <span style={{ color: "#FB8686" }}>unique business need</span>
      </>
    ),
    image: "/services/websitebuild.webp",
  },
  // {
  //   heading: "NamNews",
  //   desc1: "we help local newspapers to thrive",
  //   desc2: "with robust online presence",
  //   desc3: "",
  //   image: "/homepage/webnam.webp",
  // },
];

function HomePageNew() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Helmet>
        <title>
          Byte Crafts - Who We Are and Our Innovative Digital Solutions
        </title>
        <meta
          name="description"
          content="We are ByteCrafts: Building your digital ecosystem with tailor-made innovative solutions."
        />
      </Helmet>
      <Section1 />
      <NoticeBar brandNames={brandNames} />

      <Grid
        item
        xs={12}
        sx={{ marginLeft: { xs: "6vh", sm: "14vh" }, marginTop: "50px" }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "20px",
              sm: "40px",
              md: "50px",
              lg: "50px",
              xl: "50px",
            },
          }}
          fontWeight={700}
          color={"#FB8686"}
        >
          What we do
        </Typography>
      </Grid>

      {brands.map((brand, index) =>
        isMobile ? (
          <CardsMobile key={index} brands={brand} index={index + 1} />
        ) : (
          <Cards key={index} brands={brand} index={index + 1} />
        )
      )}
      <div style={{ marginTop: "60px" }}>
        <ProductsCarousel />
      </div>
    </div>
  );
}

export default HomePageNew;
